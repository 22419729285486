
    import { defineComponent, reactive, toRefs, PropType, ref, watch } from 'vue';
    import { NewRegionProps, Bu } from '../types';
    import { newRegion, updateRegion, getRegionDetail } from '@/API/dealerMaster';
    import { message } from "ant-design-vue";

    export default defineComponent({
        name: "NewAddEditRegion",
        emits: ['update:visible', 'update:regionId', 'success'],
        props: {
            bu: {
                type: [] as PropType<Bu[]>,
                required: true
            },
            visible: {
                type: Boolean,
                default: false,
                required: true
            },
            regionId: {
                type: Number
            }
        },
        setup(props, { emit }) {
            const formRef = ref();

            const formData = reactive<NewRegionProps>({
                buId: null,
                regionCn: '',
                regionEn: '',
                subRegionCn: '',
                subRegionEn: '',
                buNameCn: '',
                buNameEn: ''
            });

            const rules = {
                buId: [{ required: true, type: "number", message: '请选择BU', trigger: 'blur' }],
                regionCn: [{ required: true, message: '请输入RegionCn', trigger: 'blur' }],
                regionEn: [{ required: true, message: '请输入RegionEn', trigger: 'blur' }],
                subRegionCn: [{ required: true, message: '请输入Sub RegionCn', trigger: 'blur' }],
                subRegionEn: [{ required: true, message: '请输入Sub RegionEn', trigger: 'blur' }],
            }

            const handlerCancel = () => {
                emit('update:visible', false);
                emit('update:regionId', '');
                formRef.value.resetFields();
            }

            const handlerConfirm = () => {
                formRef.value
                    .validate()
                    .then(() => {
                        if (props.regionId) {
                            const body = Object.assign({}, formData, { id: props.regionId });
                            const currentBu = props.bu.filter(item => item.id == body.buId);
                            body.buNameCn = currentBu[0].nameCn;
                            body.buNameEn = currentBu[0].nameEn;
                            updateRegion(body).then(res => {
                                const title = props.regionId ? 'Edit Region successfully' : 'Add Region successfully';
                                message.success(title);
                                handlerCancel();
                                emit('success');
                            })
                        } else {
                            const body = Object.assign({}, formData, { id: props.regionId });
                            const currentBu = props.bu.filter(item => item.id == body.buId);
                            body.buNameCn = currentBu[0].nameCn;
                            body.buNameEn = currentBu[0].nameEn;
                            newRegion(body).then(res => {
                                const title = props.regionId ? 'Edit Region successfully' : 'Add Region successfully';
                                message.success(title);
                                handlerCancel();
                                emit('success');
                            })
                        }
                    })
            };

            watch(() => props.regionId, (val) => {
                if (val) {
                    getRegionDetail({ id: props.regionId as number }).then(res => {
                        Object.assign(formData, res);
                    })
                }
            })

            return {
                ...toRefs(formData),
                formData,
                rules,
                labelCol: { span: 6 },
                wrapperCol: { span: 16 },
                formRef,

                handlerConfirm,
                handlerCancel
            }
        }
    })
